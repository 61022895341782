import Keycloak from "../keycloak-js/lib/keycloak";
// import { useRef } from "react";

const Test: React.FC = () => {
  const keycloak = new Keycloak({
    realm: "BBM",
    clientId: "bbmnet-balcao-site",
    url: "https://pitang-bbm-dev.uc.r.appspot.com/auth",
  });

  if (!localStorage.getItem("refresh")) {
    keycloak
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      })
      .then((auth) => {
        localStorage.setItem("refresh", keycloak.refreshToken as any);
        localStorage.setItem("token", keycloak.token as any);
        console.log(keycloak.token);
        console.log("XXXXXXXXXXXXXXXXXXXXXXX");
        console.log(auth);
      })
      .catch((e) => {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
        console.log(e);
      });
  }

  // console.log(keycloak.token);

  // const authenticated = useRef(false);

  // keycloak
  //   .init({ onLoad: "login-required", checkLoginIframe: false })
  //   .then((auth) => {
  //     if (auth) {
  //       console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //     }
  //     console.log(auth);
  //     console.log(keycloak.token);
  //   })
  //   .catch((e) => {
  //     console.log(e);
  //   });

  // keycloak.login();

  return (
    <div>
      <span>oi</span>
    </div>
  );
};

export default Test;
